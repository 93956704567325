import React, { Component } from 'react';
import { Flex, Box, Heading } from 'rebass';
import Text from '../components/Text';
import Link, { navigate } from 'gatsby-link';
import styled from 'styled-components';
import Page from '../components/Page';
import { graphql } from 'gatsby';

const ALink = styled.a`
  :hover {
    cursor: pointer;
  }
`;

class menu extends Component {
  // fix so there's no undefined, go home if no props history available


  render() {
    return (
      <div>
        <Page>
          <Link to="/about">
            <Flex flexWrap="wrap" bg="#394049" p={5} justifyContent="center">
              <Text px={4} ml="10px" fontSize="5">
                👨🏻‍💻
              </Text>
              <Heading color="white" fontSize="4" px={4} pt={1}>
                {' '}
                About
              </Heading>
            </Flex>
          </Link>
          <Link to="/tools">
            <Flex flexWrap="wrap" bg="#C1292E" p={5} justifyContent="center">
              <Text px={4} ml="10px" fontSize="5">
              🛠
              </Text>
              <Heading color="white" fontSize="4" px={4} pt={1}>
                {' '}
                Toolkit
              </Heading>
            </Flex>
          </Link>
          <Link to="/community">
            <Flex flexWrap="wrap" bg="base" p={5} justifyContent="center">
              <Text px={4} ml="70px" fontSize="5">😎
              </Text>
              <Heading color="white" fontSize="4" px={4} pt={1}>
                {' '}
                Community
              </Heading>
            </Flex>
          </Link>

          <a  data-formkit-toggle="304ed3d90e"
                href="https://pages.convertkit.com/304ed3d90e/c61a263e4e">
            <Flex flexWrap="wrap" bg="#58355E" p={5} justifyContent="center">
              <Text px={4} ml="65px" fontSize="5">
                📡
              </Text>
              <Heading color="white" fontSize="4" px={4} pt={1}>
                {' '}
                Newsletter
              </Heading>
            </Flex>
          </a>
        </Page>
      </div>
    );
  }
}

export default menu;
